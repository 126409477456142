
import { defineComponent ,reactive , ref, toRefs} from 'vue'
import BuSelector from '@/views/LocalRegistration/components/BuSelector.vue'
import ApplicableTypeClassSelector from '@/views/LocalRegistration/components/ApplicableTypeClassSelector.vue'
import CalculationRuleSelector from '@/views/LocalRegistration/components/CalculationRuleSelector.vue'
import {getCalLicenceComparisonInfo, exportCalLicenceComparisonInfo} from '@/API/localRegistration'
import { downloadFromStream } from '@/utils/payment/downloadFile'
import { getFirstLevelBU } from '@/utils'
interface SearchType{
  bu: string|null;
  calRuleCode1: string|null;
  calRuleCode2: string|null;
  endTime: string|null;
  startTime: string|null;
  typeClass: string|null;
}

interface CRType{
  id: string;
  name: string;
}

export default defineComponent({
  components: {
    BuSelector,
    ApplicableTypeClassSelector,
    CalculationRuleSelector
  },
  setup(props) {
    const searchParams = reactive<SearchType>({
      bu: getFirstLevelBU()?.nameEn ?? null,
      calRuleCode1: null,
      calRuleCode2: null,
      endTime: null,
      startTime: null,
      typeClass: null,
    })
    const dataSource = ref<any[]>([]);
    const columns = [
      {
        title: 'RT Date',
        dataIndex: 'rtDate',
        key: 'rtDate',
        width:200
      },
      {
        title: 'VIN',
        dataIndex: 'vin',
        key: 'vin',
        width:200
      },
      {
        title: 'Dealer Code',
        dataIndex: 'dealerCode',
        key: 'dealerCode',
        width:200
      },
      {
        title: 'Dealer Name',
        dataIndex: 'dealerName',
        key: 'dealerName',
        width:200
      },
      {
        title: 'Applicable Type Class',
        dataIndex: 'typeClass',
        key: 'typeClass',
        width:200
      },
      {
        title: 'Calculation rule 1',
        children: [
          {
            title: 'REG Date',
            dataIndex: 'regDate1',
            key: 'regDate1',
            width:200
          },
          {
            title: 'Reg_City',
            dataIndex: 'regCity1',
            key: 'regCity1',
            width:200
          },
          {
            title: 'Local Reg Vol',
            dataIndex: 'localRegVol1',
            key: 'localRegVol1',
            width:200
          },
          {
            title: '未判定为本地上牌原因',
            dataIndex: 'reason1',
            key: 'reason1',
            width:500
          }
        ]
      },
      {
        title: 'Calculation rule 2',
        children: [
          {
            title: 'REG Date',
            dataIndex: 'regDate2',
            key: 'regDate2',
            width:200
          },
          {
            title: 'Reg_City',
            dataIndex: 'regCity2',
            key: 'regCity2',
            width:200
          },
          {
            title: 'Local Reg Vol',
            dataIndex: 'localRegVol2',
            key: 'localRegVol2',
            width:200
          },
          {
            title: '未判定为本地上牌原因',
            dataIndex: 'reason2',
            key: 'reason2',
            width:500
          }
        ]
      }
    ]

    const getParams = ()=>{
      const param = {
        bu: searchParams.bu || "",
        endTime: searchParams.endTime || "",
        startTime: searchParams.startTime || "",
        calRuleCode1: searchParams.calRuleCode1 || "",
        calRuleCode2: searchParams.calRuleCode2 || "",
        typeClass:searchParams.typeClass || "",
      }

      return param;
    }

    const resetSearchData = ()=>{
      Object.assign(searchParams,{
        bu: getFirstLevelBU()?.nameEn ?? null,
        endTime: null,
        startTime: null,
        calRuleCode1: null,
        calRuleCode2: null,
        typeClass: null,
      })
    }

    const searchData = ()=>{
      
      getCalLicenceComparisonInfo(getParams()).then((res: any) => {
        console.log(res)
        dataSource.value = res;
      })
    }

    const handleExport = async () => {
      const res = await exportCalLicenceComparisonInfo(getParams())
      downloadFromStream(res)
    }

    return {
      dataSource,resetSearchData,
      searchData,
      columns,...toRefs(searchParams),
      handleExport
    }
  }
})
